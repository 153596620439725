import { 
  postTrackerCount,
  postTrackerGroup, 
  postTrackerList, 
  postTrackerStates, 
} from 'services/tracker'

export const getCombinedTrackerData = async (
  inputSignal, 
  inputBodyParams, 
  isWithStates = false,
  primaryColor = '#000000',
  inputAxios,
  setIsLoading,
) => {
  try {
    setIsLoading(true)

    const resTrackers = await postTrackerList(inputSignal, inputBodyParams, inputAxios)
    
    if (resTrackers?.status === 200) {
      const { list: trackers } = resTrackers?.data
      const { list: resGroups } = (await postTrackerGroup(inputSignal, inputBodyParams, inputAxios))?.data
      const groups = resGroups.reduce((obj, item) => Object.assign(obj, { [item.id]: {...item} }), {})
  
      const unblockedTrackers = trackers.filter(item => !item.source?.blocked).map(item => item.id)
          
      const { states } = isWithStates ? (
        await postTrackerStates(inputSignal, {
          ...inputBodyParams,
          trackers: unblockedTrackers,
        }, inputAxios)
      )?.data : {
        states: null
      }
  
      const { value: odometer } = isWithStates ? (
        await postTrackerCount(inputSignal, {
          ...inputBodyParams,
          trackers: unblockedTrackers,
          type: 'odometer',
        }, inputAxios)
      )?.data : {
        odometer: null
      }
  
      const { value: engineHours } = isWithStates ? (
        await postTrackerCount(inputSignal, {
          ...inputBodyParams,
          trackers: unblockedTrackers,
          type: 'engine_hours',
        }, inputAxios)
      )?.data : {
        engineHours: null
      }
  
      setIsLoading(false)
  
      const newTrackerList = trackers.map((value) => ({
        ...value,
        state: states ? states[value.id] : null,
        group: value?.group_id !== 0 ? groups[value.group_id] : {
          color: primaryColor,
          title: 'Main Group',
          id: 0
        },
        odometer: odometer?.[value.id],
        engine_hours: engineHours?.[value.id],
      }))
    
      return newTrackerList
    } else {
      setIsLoading(false)
      return {
        status: 'failed'
      }
    }
  }
  catch (error) {
    setIsLoading(false)

    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}