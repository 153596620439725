//MUI MATERIAL
import Stack from '@mui/material/Stack'

//COMPONENTS
// import Drawer from 'components/Drawer/Drawer'

//STYLE
import privateUseStyle from './privateUseStyle'

const Private = (props) => {
  const { children } = props
  const classes = privateUseStyle()

  return (
    <Stack
      direction='row'
      className={`${classes.pageContainer} no-zoom`}
    >
      {/* <Drawer className={'zoom'}/> */}
      <Stack
        component='main'
        className={`${classes.contentContainer} zoom`}
      >
        {children}
      </Stack>
    </Stack>
  )
}

export default Private